'use client';

import { useCallback } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Card, CardActionArea, CardActions, Chip, Typography, Stack, Tooltip } from '@mui/material';
import { ImageWithFallback } from '@qb/frontend/components/ImageWithFallback';
import { LoadingButton } from '@qb/frontend/components/LoadingButton';
import { clampLinesSx } from '@qb/frontend/utils/clampLinesSx';
import { formatCents, getDiscountPercentage } from '@qb/frontend/utils/formatCurrency';
import { formatNumber } from '@qb/frontend/utils/formatNumber';
import { getConfigFromTenantAlias, TenantAlias } from '@/shared/tenants-alias-map';
import { PartPopularPartsDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
import { generatePartURL } from '@/shared/utils/URLs';
import { PartCardShipProcessingDays } from './components/PartCardShipProcessingDays';

// user doesn't have the option to set qty in this component
const BUY_NOW_QTY = 1;
type PartCardLargeProps = {
  tenantAlias: TenantAlias;
  part: PartPopularPartsDataResponse['parts'][0] & {
    shipProcessingDays?: number;
  };
  authUserCompanyName?: string;
  onClick?: () => void;
};
export const PartCardLarge = ({
  tenantAlias,
  part,
  onClick,
  authUserCompanyName
}: PartCardLargeProps) => {
  const combinedStock = part.onHandQty + part.manufacturerStock;
  const isMarketablePart = combinedStock > 0 && !!part.price;
  const tenantConfig = getConfigFromTenantAlias(tenantAlias);
  const discountPercentage = getDiscountPercentage(part.originalPrice, part.price);
  const isDiscounted = !!discountPercentage;
  const href = generatePartURL(part.manufacturer.name, part.partNumber, part.id);
  const onPartView = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);
  return <Card sx={{
    backgroundColor: 'background.paper',
    border: '2px solid',
    borderColor: 'primary.50p',
    overflow: 'hidden',
    borderRadius: 0
  }} data-sentry-element="Card" data-sentry-component="PartCardLarge" data-sentry-source-file="PartCardLarge.tsx">
      <CardActionArea onClick={onPartView} href={href} tabIndex={-1} sx={{
      px: 2,
      pt: 1,
      pb: isMarketablePart ? 0.5 : 1.5
    }} data-sentry-element="CardActionArea" data-sentry-source-file="PartCardLarge.tsx">
        {!!combinedStock && !!part.price && <PartCardShipProcessingDays shipProcessingDays={part.shipProcessingDays} />}
        <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        position: 'relative'
      }} data-sentry-element="Box" data-sentry-source-file="PartCardLarge.tsx">
          <ImageWithFallback tenantAlias={tenantAlias} src={part.images[0]?.target} alt={`${part.partNumber} on ${tenantConfig.companyName}`} width={222} height={90} data-sentry-element="ImageWithFallback" data-sentry-source-file="PartCardLarge.tsx" />
        </Box>
        <Typography variant="h4" component="h3" sx={{
        my: 1,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      }} data-sentry-element="Typography" data-sentry-source-file="PartCardLarge.tsx">
          {part.partNumber}
        </Typography>
        <Stack data-sentry-element="Stack" data-sentry-source-file="PartCardLarge.tsx">
          {!!part.price && !isDiscounted && <Typography variant="bodyLargePrimary" sx={{
          fontWeight: 600,
          color: 'text.primary',
          mb: 1
        }}>
              {formatCents(part.price)}
            </Typography>}
          {!!part.price && isDiscounted && <Box sx={{
          bgcolor: 'accent.main',
          pl: 0.5,
          pt: 0.25,
          pb: 0.25,
          pr: 4,
          mr: 'auto',
          mb: 0.5,
          display: 'inline-block',
          borderRadius: '0 50px 50px 0'
        }}>
              <Typography variant="bodyLargePrimary" sx={{
            fontWeight: 600,
            color: 'text.primary',
            mb: 1
          }}>
                {formatCents(part.price)}
                {!!part.originalPrice && <Typography component="span" variant="bodySmall" sx={{
              fontSize: 10,
              fontWeight: 600,
              display: 'inline-block',
              textDecoration: 'line-through',
              ml: 1,
              position: 'relative',
              top: '-2px'
            }}>
                    {formatCents(part.originalPrice)}
                  </Typography>}
              </Typography>
              <Typography variant="bodySmall" sx={{
            color: 'text.primary',
            mt: -1
          }}>
                {authUserCompanyName} special price{' '}
                <Tooltip title="This price includes special discounts for your company.">
                  <InfoIcon sx={{
                fontSize: 12,
                cursor: 'pointer',
                mb: '-2px'
              }} />
                </Tooltip>
              </Typography>
            </Box>}
        </Stack>
        <Chip icon={<CheckCircleIcon />} color="success" size="small" label={<>
              Verified stock: <strong>{formatNumber(combinedStock)}</strong>
            </>} sx={{
        width: 'fit-content',
        mb: 1.5,
        mt: 0.5,
        visibility: combinedStock ? 'visible' : 'hidden'
      }} data-sentry-element="Chip" data-sentry-source-file="PartCardLarge.tsx" />
        <Typography variant="bodyMedium" sx={{
        mb: 1,
        height: 51,
        ...clampLinesSx(3)
      }} data-sentry-element="Typography" data-sentry-source-file="PartCardLarge.tsx">
          {part.description}
        </Typography>
        <Typography sx={{
        fontWeight: 600,
        color: 'text.primary'
      }} data-sentry-element="Typography" data-sentry-source-file="PartCardLarge.tsx">
          {part.manufacturer.name}
        </Typography>
      </CardActionArea>
      {isMarketablePart && <CardActions sx={{
      mb: 1
    }}>
          <LoadingButton fullWidth variant="contained" sx={{
        whiteSpace: 'nowrap'
      }} loading={false} tabIndex={-1} onClick={() => {
        window?.salesPilotPostMessages?.buyNow({
          qty: BUY_NOW_QTY,
          partID: part.id
        });
      }}>
            Buy now
          </LoadingButton>
        </CardActions>}
    </Card>;
};