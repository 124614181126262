'use client';

import { Box, Typography, SvgIcon } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { ReactComponent as IconParcel } from '@qb/frontend/icons/icon-parcel.svg';
type Props = {
  shipProcessingDays: number | undefined;
};
export const PartCardShipProcessingDays = ({
  shipProcessingDays
}: Props) => {
  const isMobile = useIsMobile();
  if (shipProcessingDays === undefined) {
    return null;
  }
  const twentyFourHoursMessage = isMobile ? 'Shipping in 24h!' : 'This item can ship within 24h!';
  const message = shipProcessingDays > 0 ? `This item can ship within ${shipProcessingDays} days!` : twentyFourHoursMessage;
  return <Box sx={{
    ml: 'auto',
    mr: -2,
    mb: 0.5,
    textAlign: 'right'
  }} data-sentry-element="Box" data-sentry-component="PartCardShipProcessingDays" data-sentry-source-file="PartCardShipProcessingDays.tsx">
      <Typography variant="bodySmallPrimary" sx={{
      fontSize: {
        xs: 10,
        md: 11
      },
      position: 'relative',
      height: 27,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'end',
      backgroundColor: 'success.main',
      color: 'common.white',
      pl: 0.4,
      pr: 1,
      '&:after': {
        position: 'absolute',
        content: '""',
        display: 'inline-block',
        width: '0',
        height: '0',
        borderStyle: 'solid',
        // make it a bit bigger to eliminate the thin border due to the anti-aliasing effect
        left: -1,
        top: -4,
        borderWidth: '32px 0 0 8px',
        borderColor: 'transparent transparent transparent #fff'
      }
    }} data-sentry-element="Typography" data-sentry-source-file="PartCardShipProcessingDays.tsx">
        <SvgIcon component={IconParcel} inheritViewBox sx={{
        color: 'common.white',
        fontSize: 24,
        mr: 1
      }} data-sentry-element="SvgIcon" data-sentry-source-file="PartCardShipProcessingDays.tsx" />
        {message}
      </Typography>
    </Box>;
};