import { range } from 'lodash';
import { Stack, Typography } from '@mui/material';
import { ViewAllPartsButton } from '@qb/frontend/components/OmniSearch/_components/ViewAllPartsButton';
import { usePartsAutocompleteRequest } from '@qb/frontend/components/OmniSearch/_hooks/usePartsAutocompleteRequest';
import { PartCardHorizontal, PartCardHorizontalSkeleton } from '@qb/frontend/components/PartCards/PartCardHorizontal';
import { TenantAliasProps } from '@qb/frontend/types/tenantAlias.types';
import { isNullableOrEmptyArray } from '@/shared/types/util/TypeGuards';
import { NoPartResults } from './NoPartResults';
type PartQueryProps = TenantAliasProps & {
  query: string;
};
export const PartQuery = ({
  query,
  tenantAlias
}: PartQueryProps) => {
  const {
    arePartsLoading,
    parts
  } = usePartsAutocompleteRequest(query, tenantAlias);
  const title = <Typography variant="h6" sx={{
    mb: 2,
    color: 'text.secondary'
  }}>
      Parts
    </Typography>;
  if (arePartsLoading) {
    return <>
        {title}
        <Stack spacing={1}>
          {range(4).map(index => <PartCardHorizontalSkeleton key={index} />)}
        </Stack>
      </>;
  }
  if (isNullableOrEmptyArray(parts)) {
    return <NoPartResults tenantAlias={tenantAlias} />;
  }
  return <>
      {title}
      {parts.map((part, index) => <PartCardHorizontal key={part.id} part={part} tenantAlias={tenantAlias} ga4data={{
      itemListName: 'omni_search_products',
      itemIndex: index
    }} />)}
      <ViewAllPartsButton query={query} data-sentry-element="ViewAllPartsButton" data-sentry-source-file="PartQuery.tsx" />
    </>;
};