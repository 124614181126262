'use client';

import { range } from 'lodash';
import { Box, Card, Skeleton, Stack, Typography } from '@mui/material';
import { PartCategoryResult } from '@qb/frontend/components/OmniSearch/_components/OmniSearchDesktopPopup/components/Categories/PartCategoryResult';
import { MascotAnimation } from '@qb/frontend/components/OmniSearch/_components/OmniSearchDesktopPopup/components/MascotAnimation/MascotAnimation';
import { useSearchCategoriesRequest } from '@qb/frontend/components/OmniSearch/_hooks/useSearchCategoriesRequest';
import { TenantAliasProps } from '@qb/frontend/types/tenantAlias.types';
import { ViewAllCategoriesButton } from '../../ViewAllCategoriesButton';
export const NoQuery = ({
  tenantAlias
}: TenantAliasProps) => {
  const {
    searchCategories,
    areSearchCategoriesLoading
  } = useSearchCategoriesRequest(tenantAlias);
  const greeting = `Hey you!`;
  return <Card data-sentry-element="Card" data-sentry-component="NoQuery" data-sentry-source-file="NoQuery.tsx">
      <Stack direction="row" data-sentry-element="Stack" data-sentry-source-file="NoQuery.tsx">
        <Box sx={{
        flex: 1,
        bgcolor: 'common.backgroundColor',
        py: 1,
        px: 2,
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)'
      }} data-sentry-element="Box" data-sentry-source-file="NoQuery.tsx">
          {!areSearchCategoriesLoading && searchCategories ? searchCategories.map(searchCategory => <PartCategoryResult tenantAlias={tenantAlias} partCategory={searchCategory} key={searchCategory.id} sx={{
          maxWidth: 200,
          height: 58
        }} />) : range(9).map(i => <Skeleton key={i} sx={{
          width: 200,
          height: 58
        }} />)}
          <ViewAllCategoriesButton sx={{
          height: 58,
          gridColumn: '2 / 3',
          gridRow: 'auto / span 2'
        }} data-sentry-element="ViewAllCategoriesButton" data-sentry-source-file="NoQuery.tsx" />
        </Box>
        <Stack direction="column" sx={{
        alignItems: 'flex-end',
        width: 235,
        p: 2
      }} data-sentry-element="Stack" data-sentry-source-file="NoQuery.tsx">
          <MascotAnimation tenantAlias={tenantAlias} variant="friendly" sx={{
          mb: 6
        }} data-sentry-element="MascotAnimation" data-sentry-source-file="NoQuery.tsx" />
          <Box sx={{
          position: 'relative',
          width: '100%',
          backgroundColor: 'common.backgroundColor',
          p: 2,
          borderRadius: 2,
          '&:after': {
            position: 'absolute',
            content: '""',
            right: 0,
            top: -35,
            display: 'inline-block',
            width: 40,
            height: 40,
            backgroundColor: 'common.backgroundColor',
            clipPath: 'polygon(100% 0, 50% 100%, 100% 100%)'
          }
        }} data-sentry-element="Box" data-sentry-source-file="NoQuery.tsx">
            <Typography variant="h5" sx={{
            mb: 1
          }} data-sentry-element="Typography" data-sentry-source-file="NoQuery.tsx">
              {greeting}
            </Typography>
            <Typography variant="bodyMedium" data-sentry-element="Typography" data-sentry-source-file="NoQuery.tsx">
              Check out our parts categories!
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Card>;
};