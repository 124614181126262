'use client';

import { useCallback } from 'react';
import { Box, Drawer } from '@mui/material';
import { NoQuery } from '@qb/frontend/components/OmniSearch/_components/OmniSearchMobileDrawer/components/NoQuery';
import { Query } from '@qb/frontend/components/OmniSearch/_components/OmniSearchMobileDrawer/components/Query';
import { SearchDrawerInput } from '@qb/frontend/components/OmniSearch/_components/OmniSearchMobileDrawer/components/SearchDrawerInput';
import { useDebouncedQuery } from '@qb/frontend/hooks/useDebouncedQuery';
import { TenantAliasProps } from '@qb/frontend/types/tenantAlias.types';
import { OmniSearchInputG4AEventsProps } from '../OmniSearchInput';
type OmniSearchMobileDrawerProps = TenantAliasProps & OmniSearchInputG4AEventsProps & {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  handlerRedirect: (href: string) => void;
};
export const OmniSearchMobileDrawer = ({
  isVisible,
  setIsVisible,
  searchInputName,
  tenantAlias,
  handlerRedirect
}: OmniSearchMobileDrawerProps) => {
  const {
    query,
    setQuery,
    debouncedQuery
  } = useDebouncedQuery('', 300);
  const onResultClick = useCallback(() => setIsVisible(false), [setIsVisible]);
  return <Drawer open={isVisible} onClose={() => setIsVisible(false)} PaperProps={{
    sx: {
      width: '100%',
      maxWidth: 400
    }
  }} data-sentry-element="Drawer" data-sentry-component="OmniSearchMobileDrawer" data-sentry-source-file="OmniSearchMobileDrawer.tsx">
      <Box sx={{
      p: 1
    }} data-sentry-element="Box" data-sentry-source-file="OmniSearchMobileDrawer.tsx">
        <SearchDrawerInput searchInputName={searchInputName} value={query} onChange={e => setQuery(e.target.value)} onClear={() => setQuery('')} onClose={() => setIsVisible(false)} handlerRedirect={handlerRedirect} data-sentry-element="SearchDrawerInput" data-sentry-source-file="OmniSearchMobileDrawer.tsx" />
      </Box>
      {query ? <Query query={debouncedQuery} onResultClick={onResultClick} tenantAlias={tenantAlias} /> : <NoQuery onResultClick={onResultClick} tenantAlias={tenantAlias} />}
    </Drawer>;
};