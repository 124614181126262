import { notFound } from 'next/navigation';
import qs from 'qs';
import {
  API_PREFIXES,
  getRestApiURLforTenant,
  getSPSApiURLForTenant,
} from '@qb/frontend/utils/hosts/apiHost';
import { TenantAlias } from '@/shared/tenants-alias-map';
import { GetAppResponse } from '@/shared/types/controllers/AppControllerTypes';
import { QuotePDFResponse } from '@/shared/types/controllers/PDFControllerTypes';
import {
  AppSPSConfigResponse,
  AppPDFConfigResponse,
} from '@/shared/types/controllers/sps/SPSAppControllerTypes';
import {
  GetManufacturerLineCardComponentDataResponse,
  GetSPSPageComponentsByPageResponse,
} from '@/shared/types/controllers/sps/SPSPageComponentControllerTypes';
import {
  SPSPartCategoryAllCategoriesResponse,
  SPSPartCategorySearchCategoriesResponse,
} from '@/shared/types/controllers/sps/SPSPartCategoryControllerTypes';
import {
  PartDisplayDataResponse,
  PartMatchByPartNumberDataResponse,
  PartPopularPartsDataResponse,
  PartPublicQtyAndPriceResponse,
  PartRelevantPartsDataResponse,
} from '@/shared/types/controllers/sps/SPSPartControllerTypes';
import {
  SearchPartCategoryAutocompleteResponse,
  SearchPartAutocompleteResponse,
  SearchDataPayload,
  SearchDataResponse,
  GetAllManufacturerSearchDataResponse,
  GetManufacturerSearchDataResponse,
  SearchPartsResponse,
  SearchPartsPayload,
} from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
import { postRequestPromise } from './RequestPromise';

const SPS_TIMEOUT_MS = 10000;

export const get = async <ResponseBody>(
  url: string,
  params?: Record<string, unknown>,
) => {
  let urlWithParams = url;
  if (params) {
    urlWithParams += `?${qs.stringify(params)}`;
  }

  const response = await fetch(urlWithParams, {
    // Uncomment the `cache` option if testing it well
    // @see https://nextjs.org/docs/app/api-reference/functions/fetch#optionscache
    // cache: 'force-cache',
    signal: AbortSignal.timeout(SPS_TIMEOUT_MS),
    credentials: 'include',
  });

  if (response.status === 404) {
    return notFound();
  }

  if (!response.ok) {
    throw {
      status: response.status,
      statusText: response.statusText,
    };
  }
  const data = await response.json();

  return data as Promise<ResponseBody>;
};

export const spsQueries = {
  app: {
    queryKey: ['app'],
    get: (hostName: string, tenantAlias: TenantAlias, appID: number) => ({
      queryKey: ['app', appID],
      queryFn: () =>
        get<GetAppResponse>(
          `${getRestApiURLforTenant(tenantAlias, hostName)}/app/${appID}`,
        ),
    }),
    spsConfig: (hostName: string, tenantAlias: TenantAlias, appID: number) => ({
      queryKey: ['app', 'spsConfig', appID],
      queryFn: () =>
        get<AppSPSConfigResponse>(
          `${getSPSApiURLForTenant(
            tenantAlias,
            hostName,
          )}/app/${appID}/SPSConfig`,
        ).then((res) => res.spsConfig),
    }),
    pdfConfig: (hostName: string, tenantAlias: TenantAlias, appID: number) => ({
      queryKey: ['app', 'spsConfig', appID],
      queryFn: () =>
        get<AppPDFConfigResponse>(
          `${getSPSApiURLForTenant(
            tenantAlias,
            hostName,
          )}/app/${appID}/PDFConfig`,
        ).then((res) => res.pdfConfig),
    }),
  },
  part: {
    queryKey: ['part'],
    recordPartView: (
      hostName: string,
      tenantAlias: TenantAlias,
      partID: number,
    ) => ({
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getSPSApiURLForTenant(
            tenantAlias,
            hostName,
          )}/part/${partID}/recordPartView`,
        ),
    }),
    matchByPartNumber: (
      hostName: string,
      tenantAlias: TenantAlias,
      partNumber: string,
    ) => ({
      queryKey: ['part', 'matchByPartNumber', partNumber],
      queryFn: () =>
        get<PartMatchByPartNumberDataResponse>(
          `${getSPSApiURLForTenant(
            tenantAlias,
            hostName,
          )}/part/${partNumber}/matchByPartNumber`,
        ),
    }),
    displayData: (
      hostName: string,
      tenantAlias: TenantAlias,
      partID: number,
    ) => ({
      queryKey: ['part', 'displayData', partID],
      queryFn: () =>
        get<PartDisplayDataResponse>(
          `${getSPSApiURLForTenant(tenantAlias, hostName)}/part/${partID}`,
        ),
    }),
    relevantParts: (
      hostName: string,
      tenantAlias: TenantAlias,
      partID: number,
    ) => ({
      queryKey: ['part', 'relevantParts', partID],
      queryFn: () =>
        get<PartRelevantPartsDataResponse>(
          `${getSPSApiURLForTenant(
            tenantAlias,
            hostName,
          )}/part/${partID}/relevantParts`,
        ),
    }),
    popularParts: (hostName: string, tenantAlias: TenantAlias) => ({
      queryKey: ['part', 'popularParts'],
      queryFn: () =>
        get<PartPopularPartsDataResponse>(
          `${getSPSApiURLForTenant(tenantAlias, hostName)}/part/popularParts`,
        ),
    }),
    publicQtyAndPrice: (
      hostName: string,
      tenantAlias: TenantAlias,
      partID: number,
    ) => ({
      queryKey: ['part', 'publicQtyAndPrice', partID],
      queryFn: () =>
        get<PartPublicQtyAndPriceResponse>(
          `${getSPSApiURLForTenant(
            tenantAlias,
            hostName,
          )}/part/${partID}/publicQtyAndPrice`,
        ),
    }),
  },
  // PDF queries are different in that they should not use api prefix because they are NOT tenant based
  pdf: {
    queryKey: ['pdf'],
    quote: (quoteID: number, quoteShareToken: string) => ({
      queryKey: ['pdf', 'quote', quoteID],
      queryFn: () =>
        get<QuotePDFResponse>(
          `${process.env.API_HOST}${API_PREFIXES['WEB']}/pdf/quote/${quoteID}`,
          {
            quoteShareToken,
          },
        ),
    }),
  },
  search: {
    queryKey: ['search'],
    partCategoryAutocomplete: (
      hostName: string,
      tenantAlias: TenantAlias,
      query: string,
    ) => ({
      queryKey: ['search', 'partCategoryAutocomplete', query],
      queryFn: () =>
        get<SearchPartCategoryAutocompleteResponse>(
          `${getSPSApiURLForTenant(
            tenantAlias,
            hostName,
          )}/search/partCategoryAutocomplete`,
          { query },
        ),
    }),
    partAutocomplete: (
      hostName: string,
      tenantAlias: TenantAlias,
      query: string,
    ) => ({
      queryKey: ['search', 'partAutocomplete', query],
      queryFn: () =>
        get<SearchPartAutocompleteResponse>(
          `${getSPSApiURLForTenant(
            tenantAlias,
            hostName,
          )}/search/partAutocomplete`,
          { query },
        ),
    }),
    searchData: (
      hostName: string,
      tenantAlias: TenantAlias,
      searchDataPayload: SearchDataPayload,
    ) => ({
      queryKey: ['search', 'searchData', searchDataPayload],
      queryFn: () =>
        get<SearchDataResponse>(
          `${getSPSApiURLForTenant(tenantAlias, hostName)}/search/searchData`,
          searchDataPayload,
        ),
    }),
    searchParts: (
      hostName: string,
      tenantAlias: TenantAlias,
      searchDataPayload: SearchPartsPayload,
    ) => ({
      queryKey: ['search', 'searchParts', searchDataPayload],
      queryFn: async () => {
        return get<SearchPartsResponse>(
          `${getSPSApiURLForTenant(tenantAlias, hostName)}/search/searchParts`,
          searchDataPayload,
        );
      },
    }),
    manufacturers: (hostName: string, tenantAlias: TenantAlias) => ({
      queryKey: ['search', 'manufacturers'],
      queryFn: () =>
        get<GetAllManufacturerSearchDataResponse>(
          `${getSPSApiURLForTenant(
            tenantAlias,
            hostName,
          )}/search/manufacturers`,
        ),
    }),
    manufacturer: (
      hostName: string,
      tenantAlias: TenantAlias,
      manufacturerID: number,
    ) => ({
      queryKey: ['search', 'manufacturer', manufacturerID],
      queryFn: () =>
        get<GetManufacturerSearchDataResponse>(
          `${getSPSApiURLForTenant(
            tenantAlias,
            hostName,
          )}/search/manufacturer/${manufacturerID}`,
        ),
    }),
  },
  partCategory: {
    queryKey: ['partCategory'],
    searchCategories: (hostName: string, tenantAlias: TenantAlias) => ({
      queryKey: ['partCategory', 'searchCategories'],
      queryFn: () =>
        get<SPSPartCategorySearchCategoriesResponse>(
          `${getSPSApiURLForTenant(
            tenantAlias,
            hostName,
          )}/partCategory/searchCategories`,
        ).then((res) => res.searchCategories),
    }),
    allCategories: (hostName: string, tenantAlias: TenantAlias) => ({
      queryKey: ['partCategory', 'allCategories'],
      queryFn: () =>
        get<SPSPartCategoryAllCategoriesResponse>(
          `${getSPSApiURLForTenant(
            tenantAlias,
            hostName,
          )}/partCategory/allCategories`,
        ).then((res) => res.partCategories),
    }),
  },
  pageComponent: {
    queryKey: ['pageComponent'],
    componentsByPage: (
      hostName: string,
      tenantAlias: TenantAlias,
      page: string,
    ) => ({
      queryKey: ['pageComponent', 'componentsByPage', page],
      queryFn: () =>
        get<GetSPSPageComponentsByPageResponse>(
          `${getSPSApiURLForTenant(
            tenantAlias,
            hostName,
          )}/pageComponent/${encodeURIComponent(page)}`,
        ).then((res) => res.components),
    }),
    getManufacturerLineCardComponentData: (
      hostName: string,
      tenantAlias: TenantAlias,
      pageComponentID: number,
    ) => ({
      queryKey: [
        'pageComponent',
        'getManufacturerLineCardComponentData',
        pageComponentID,
      ],
      queryFn: () =>
        get<GetManufacturerLineCardComponentDataResponse>(
          `${getSPSApiURLForTenant(
            tenantAlias,
            hostName,
          )}/pageComponent/${pageComponentID}/getManufacturerLineCardComponentData`,
        ),
    }),
  },
};
