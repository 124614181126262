import { memoize } from 'lodash';

/**
 * Resolving Intl.NumberFormat options and creating a new instance is a slow operation, it's best to memoize it.
 */
const NumberFormat = memoize(Intl.NumberFormat, (...args: unknown[]) =>
  JSON.stringify(args),
);

/**
 * @example (1234567) => '1,234,567'
 */
export const formatNumber = (value: number) =>
  NumberFormat('en-US', {
    style: 'decimal',
  }).format(value);
